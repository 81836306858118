.App {
  text-align: center;
}
html {
  overflow-y: scroll;
}

.floating-content {
  width: 55%;
  margin: 0 auto;
  margin-top: 2%;
  background-color: #fff;
  border-radius: 15px;
  padding: 5px;
  white-space: normal;
  word-wrap: break-word;
  min-width: 250px;
}

.blurb {
  padding: 0% 20%;
  color: #000;
}

body {
  background-image: url("../images/wood2.jpg");
  background-size: cover;
}

.header {
  display: inline-block;
  padding-top: 20px;
}
.headerImageLogo {
  display: "inline";
  width: 60px;
}

.headerTitleText {
  display: inline;
  vertical-align: top;
  margin-left: 15px;
}

ul {
  list-style: none; /* Remove bullet points */
  /* padding: 0; Remove default padding */
}

li {
  display: flex; /* Use flexbox layout */
  align-items: center; /* Vertically align items */
  justify-content: center;
  /* padding-bottom: 8px; */
}

.linkText {
  margin-bottom: 10px;
  /* vertical-align: super; */
  padding-right: 20px;
}

.linkLogoImage {
  width: 25px;
  height: 25px; /* Set the height of the logo images */
  margin-right: 10px; /* Add spacing between the image and text */
}

/* Font Styling */
.quicksand-text {
  font-family: "Quicksand", sans-serif;
}
.pacifico-text {
  font-family: "Anton";
}

p,
h1 {
  color: #000000;
}

h3 {
  margin: 0%;
}

h4 {
  color: #000000;
  margin: 0%;
}

h5 {
  margin: 2%;
  color: #b0dc0f;
  padding: 0;
}

h6 {
  color: #000000;
}

/* unvisited link */
a:link {
  color: green;
}

/* visited link */
a:visited {
  color: green;
}

/* mouse over link */
a:hover {
  color: #b0dc0f;
}

/* selected link */
a:active {
  color: rgb(190, 190, 190);
}

.services-container {
  padding-left: 10%;
  padding-right: 10%;
}

.gallery {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); */
  justify-items: center; /* Center the items horizontally */
  align-items: center; /* Center the items vertically */
  gap: 20px; /* Increase the gap between images for better visual separation */
}

.gallery-image {
  width: 100%;
  height: auto;
  max-width: 75%;
  border-radius: 10px;
}

.call-to-action {
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  padding: 15px; /* Add padding around the text */
  border-radius: 10px; /* Add border radius to create rounded corners */
  margin-top: 20px; /* Add some space between the services and the call-to-action */
}

.ctaNumber {
  color: green;
}
